//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-720:_6152,_5072,_6904,_8648,_2076,_1915,_3492,_0;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-720')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-720', "_6152,_5072,_6904,_8648,_2076,_1915,_3492,_0");
        }
      }catch (ex) {
        console.error(ex);
      }