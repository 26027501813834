import shopifyGenericDefaults from '../shopify-generic/config.js';

const searchBoxWidget = shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox');

const onVerifyFitmentResponseReceived = (response) => {
  const addToCartButton = window.document.querySelector(
    '.product-info__buy-buttons .buy-buttons:not(.button-disabled,.parent) .button',
  );

  if (addToCartButton) {
    const fits = response.Items?.[0]?.fitsTheVehicle;

    if (fits) {
      if (fits !== 'no') {
        addToCartButton.removeAttribute('disabled');
        addToCartButton.firstChild.innerText = 'Add to cart';
      } else {
        addToCartButton.setAttribute('disabled', true);
        addToCartButton.firstChild.innerText = 'The product doesn`t fit your vehicle';
      }
    } else {
      addToCartButton.setAttribute('disabled', true);
      addToCartButton.firstChild.innerText = 'Please, select your vehicle first';
    }
  }
};

function responseHandler(response) {
  const { Actions: [{ Type, RedirectUrl: url } = {}] = [] } = response;

  const { pathname } = window.location;

  if (!pathname.includes('/products/') && Type === 'Redirect' && url && pathname !== url) {
    window.location.assign(url);
    return;
  }

  return response;
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  responseHandler,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    onVerifyFitmentInitResponseReceived: onVerifyFitmentResponseReceived,
    onVerifyFitmentResponseReceived,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchBox', 'FacetDialog'].includes(w.name)),
    // always use the regular search box, no dialog
    {
      ...searchBoxWidget,
      name: 'SearchBox',
      type: 'SearchBox',
      template: 'SearchBox',
    },
    {
      name: 'HeaderVehicleWidget_searchbox',
      type: 'VehicleWidget',
      location: '#cm-search-box-ymm',
      template: 'fitmentSearch/searchBoxVehicleWidget',
      doNotRedirectOnVehicleSelect: true,
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
      ignoreFields: ['Universal'],
      initExpandedFacets: ['stock', 'badges'],
    },
  ],
};
